<template>
  <q-form ref="editForm">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-card class="cardClassDetailForm" title="LBLBASEINFO">
          <template slot="card-button">
            <q-btn-group outline >
              <c-btn
                v-show="editable&&!isOld"
                label="타 목표 불러오기"
                icon="save_alt"
                @btnClicked="copyTarget"
              />
              <!--삭제-->  
              <c-btn 
                v-if="editable&&isOld" 
                label="LBLREMOVE" 
                icon="delete"
                @btnClicked="deleteTarget" />
              <!--저장-->
              <c-btn 
                v-if="editable" 
                :isSubmit="isSave"
                :url="saveUrl"
                :param="target"
                :mappingType="mappingType"
                label="LBLSAVE" 
                icon="save"
                @beforeAction="saveTarget"
                @btnCallback="saveTargetCallback" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-plant
                :required="true"
                :editable="editable"
                name="plantCd"
                v-model="target.plantCd">
              </c-plant>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-select
                :required="true"
                codeGroupCd="SAFETY_HEALTHY_TYPE_CD"
                type="edit"
                itemText="codeName"
                itemValue="code"
                name="safetyHealthyTypeCd"
                label="구분"
                v-model="target.safetyHealthyTypeCd"
              ></c-select>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <!--제목-->
              <c-text
                :required="true"
                default="today"
                label="제목"
                name="targetTitle"
                v-model="target.targetTitle"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <!--대상년도-->
              <c-datepicker
                :required="true"
                :editable="editable"
                type="year"
                default="today"
                label="LBL0010014"
                name="targetYear"
                v-model="target.targetYear"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <!--작성부서-->
              <c-dept 
                :required="true" 
                :editable="editable" 
                type="edit" 
                label="LBLWRITEDEPT" 
                name="writeDeptCd" 
                v-model="target.writeDeptCd" />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <!--작성일-->
              <c-datepicker
                :required="true"
                default="today"
                label="LBLREGDATE"
                name="writeDt"
                v-model="target.writeDt"
              />
            </div>
          </template>
        </c-card>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <!--ESF 목표 및 추진계획/실적-->
        <c-table
          ref="table"
          title="ESF 목표 및 추진계획/실적"
          :merge="grid.merge"
          :columns="grid.columns"
          :data="target.plans"
          :gridHeight="gridHeight"
          :editable="editable"
          :usePaging="false"
          :isExcelDown="true"
          :filtering="false"
          :isFullScreen="true"
          :columnSetting="false"
          selection="multiple"
          rowKey="saiSafetyHealthyTargetPlanId"
        >
          <template slot="table-chip">
            <q-checkbox
              dense
              class="tableCheckBox"
              v-model="trueCheck"
              disable 
              label="계획"
            />&nbsp;&nbsp;
            <q-checkbox
              dense
              class="tableCheckBox"
              v-model="trueCheck"
              disable 
              color="red"
              label="실적"
            />
          </template>
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable" label="LBLADD" icon="add" @btnClicked="addTargetPlan"/>
              <c-btn v-if="editable" label="LBLEXCEPT" icon="remove" @btnClicked="deleteTargetPlan" />
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name === 'detailName'">
              <c-text-column
                :editable="editable"
                :col="col"
                :props="props"
                @datachange="datachange(props, col)"
              />
              <div v-if="editable">
                <q-btn-group outline class="ColumInnerBtnGroup">
                  <q-btn
                    icon="add"
                    color="red-6"
                    text-color="white"
                    class="ColumInnerBtn"
                    align="center"
                    @click.prevent="innerBtnClicked(col, props)">
                    <q-tooltip>
                      {{'안전보건 목표별 항목 추가'}}
                    </q-tooltip>
                  </q-btn>
                </q-btn-group>
              </div>
            </template>
            <template v-if="col.name === 'custom' && props.row.editFlag !== 'C'">
              <!-- 
                1. 실적이 들어가지 않은경우
                - 입력하기
                2. 실적이 들어간 경우
                - ㅁ건 
              -->
              <template v-if="props.row.results && props.row.results.length > 0">
                  <!-- flat  -->
                  <!--건-->
                <q-btn 
                  dense
                  outline 
                  color="blue-9"
                  style="font-weight:700"
                  :label="`${props.row.results.length} ${$label('LBL0010049')}`" 
                  @click="openResult(props.row)" />
              </template>
              <template v-else>
                <!--입력하기-->
                <q-btn 
                  dense
                  outline  
                  color="green-9"
                  style="font-weight:700"
                  :label="$label('LBL0010042')"
                  @click="openResult(props.row)" />
              </template>
            </template>
            <template v-if="col.name==='month1' || col.name==='month2' || col.name==='month3'  || col.name==='month4'  || col.name==='month5'
              || col.name==='month6' || col.name==='month7' || col.name==='month8' || col.name==='month9' || col.name==='month10' || col.name==='month11' || col.name==='month12'">
              <q-checkbox
                dense
                class="tableCheckBox"
                :editable="editable"
                :isFlag="true"
                true-value="Y"
                false-value="N"
                :name="col.name"
                v-model="props.row[col.name]"
                @input="datachange(props, col)"
              />
              <template>
                <br>
                <q-checkbox
                  v-if="$_.findIndex(props.row.results, {month: col.resultKey}) > -1"
                  dense
                  class="tableCheckBox"
                  :isFlag="true"
                  color="red"
                  v-model="trueCheck"
                  disable 
                />
                <q-checkbox
                  v-else
                  dense
                  class="tableCheckBox"
                  :isFlag="true"
                  v-model="falseCheck"
                  disable 
                />
              </template>
            </template>
          </template>
        </c-table>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </div>
  </q-form>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';

export default {
  name: 'safety-healthy-target-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        saiSafetyHealthyTargetId: '',
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      trueCheck: true,
      falseCheck: false,
      target: {
        saiSafetyHealthyTargetId: '',  // 안전보건목표 일련번호
        plantCd: null,  // 사업장
        propelDeptName: '',  // 추진부서
        safetyHealthyTypeCd: null,
        targetYear: '',  // 대상년도
        targetTitle: '', // 제목
        writeDeptCd: '',  // 작성부서
        managerName: '',
        writeDt: '',  // 작성일
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
        plans: [],
        deletePlans: [],
      },
      grid: {
        merge: [
          { index: 0, colName: "saiSafetyHealthyId" },
          { index: 1, colName: "saiSafetyHealthyId" }
        ],
        columns: [
          {
            name: 'targetName',
            field: 'targetName',
            //목표
            label: '목표<br/>(효과)',
            align: 'center',
            style: 'width: 130px',
            sortable: false,
            fix: true,
          },
          // {
          //   name: 'deptName',
          //   field: 'deptName',
          //   //해당부서
          //   label: '해당부서',
          //   align: 'left',
          //   style: 'width: 100px',
          //   sortable: false,
          //   fix: true,
          // },
          {
            required: true,
            name: 'detailName',
            field: 'detailName',
            //세부추진사항
            label: '추진전략<br/>(개선명)',
            align: 'left',
            type: 'custom',
            style: 'width: 180px',
            sortable: false,
            fix: true,
          },
          {
            name: 'kpi',
            field: 'kpi',
            //준수지표
            label: '세부추진실천방법<br/>(추진방안)',
            align: 'left',
            style: 'width: 120px',
            type: 'textarea',
            sortable: false,
            fix: true,
          },
          {
            name: 'performanceGoal',
            field: 'performanceGoal',
            //성과목표
            label: '모니터링 절차<br/>(준수지표)',
            align: 'left',
            style: 'width: 120px',
            type: 'textarea',
            sortable: false,
            fix: true,
          },
          {
            name: 'performanceDetails',
            field: 'performanceDetails',
            //실적내용
            label: '자원',
            align: 'left',
            style: 'width: 140px',
            sortable: false,
            type: 'textarea',
          },
          {
            name: 'managerName',
            field: 'managerName',
            //실적내용
            label: '담당자',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
            setHeader: true,
            type: 'text',
          },
          {
            name: 'budget',
            field: 'budget',
            //예산(만원)
            label: '투자비용(천원)',
            align: 'right',
            style: 'width: 120px',
            sortable: false,
            type: 'number',
          },
          {
            name: 'schedule',
            field: 'schedule',
            //추진일정
            label: 'LBL0010022',
            child: [
              {
                name: 'month1',
                field: 'month1',
                resultKey: '1',
                //1월
                label: 'LBL0010023',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'custom',
                'true': 'Y',
                'false': 'N',
                color: 'blue', 
              },
              {
                name: 'month2',
                field: 'month2',
                resultKey: '2',
                //2월
                label: 'LBL0010024',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'custom',
                'true': 'Y',
                'false': 'N',
                color: 'blue', 
              },
              {
                name: 'month3',
                field: 'month3',
                resultKey: '3',
                //3월
                label: 'LBL0010025',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'custom',
                'true': 'Y',
                'false': 'N',
                color: 'blue', 
              },
              {
                name: 'month4',
                field: 'month4',
                resultKey: '4',
                //4월
                label: 'LBL0010026',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'custom',
                'true': 'Y',
                'false': 'N',
                color: 'blue', 
              },
              {
                name: 'month5',
                field: 'month5',
                resultKey: '5',
                //5월
                label: 'LBL0010027',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'custom',
                'true': 'Y',
                'false': 'N',
                color: 'blue', 
              },
              {
                name: 'month6',
                field: 'month6',
                resultKey: '6',
                //6월
                label: 'LBL0010028',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'custom',
                'true': 'Y',
                'false': 'N',
                color: 'blue', 
              },
              {
                name: 'month7',
                field: 'month7',
                resultKey: '7',
                //7월
                label: 'LBL0010029',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'custom',
                'true': 'Y',
                'false': 'N',
                color: 'blue', 
              },
              {
                name: 'month8',
                field: 'month8',
                resultKey: '8',
                //8월
                label: 'LBL0010030',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'custom',
                'true': 'Y',
                'false': 'N',
                color: 'blue', 
              },
              {
                name: 'month9',
                field: 'month9',
                resultKey: '9',
                //9월
                label: 'LBL0010031',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'custom',
                'true': 'Y',
                'false': 'N',
                color: 'blue', 
              },
              {
                name: 'month10',
                field: 'month10',
                resultKey: '10',
                //10월
                label: 'LBL0010032',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'custom',
                'true': 'Y',
                'false': 'N',
                color: 'blue', 
              },
              {
                name: 'month11',
                field: 'month11',
                resultKey: '11',
                //11월
                label: 'LBL0010033',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'custom',
                'true': 'Y',
                'false': 'N',
                color: 'blue', 
              },
              {
                name: 'month12',
                field: 'month12',
                resultKey: '12',
                //12월
                label: 'LBL0010034',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'custom',
                'true': 'Y',
                'false': 'N',
                color: 'blue', 
              },
            ]
          },
          {
            name: 'result',
            field: 'result',
            //성과
            label: 'LBL0010035',
            child: [
              {
                name: 'status',
                field: 'status',
                //현황
                label: 'LBL0010036',
                align: 'center',
                style: 'width: 100px',
                setHeader: true,
                sortable: false,
                isChip: true,
                codeGroupCd: 'SAFETY_HEALTH_STATUS_CD',
                type: 'select',
              },
              {
                name: 'achievementRate',
                field: 'achievementRate',
                //달성율(%)
                label: '달성율(%)',
                align: 'center',
                style: 'width: 80px',
                sortable: false,
                type: 'number',
              },
            ],
          },
          {
            name: 'remark',
            field: 'remark',
            //비고
            label: '개선실적',
            align: 'left',
            style: 'width: 140px',
            sortable: false,
            type: 'textarea',
          },
          {
            name: 'custom',
            field: 'custom',
            //상세실적
            label: 'LBL0010040',
            align: 'center',
            style: 'width: 80px',
            sortable: false,
            type: 'custom',
          },
        ],
        data: [],
      },
      planRow: null,
      editable: false,
      isSave: false,
      detailUrl: '',
      saveUrl: '',
      deleteUrl: '',
      mappingType: 'PUT',
      popupOptions: {
        isFull: false,
        width: '50%',
        target: null,
        title: '',
        visible: false,
        top: '50px',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridHeight() {
      return String(this.contentHeight - 180) + 'px';
    },
    isOld() {
      return Boolean(this.popupParam.saiSafetyHealthyTargetId)
    }
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sai.safetyHealthy.get.url;
      this.saveUrl = transactionConfig.sai.safetyHealthy.insert.url;
      this.deleteUrl = transactionConfig.sai.safetyHealthy.delete.url;
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.saiSafetyHealthyTargetId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
          this.$_.extend(this.target, _result.data)
        },);
      } else {
        // 신규등록 
        this.$set(this.target, 'writeDeptCd', this.$store.getters.user.deptCd)
        this.$set(this.target, 'writeDt', this.$comm.getToday())
      }
    },
    addTargetPlan() {
      this.popupOptions.title = 'LBL0010041';   //안전보건 목표 검색
      this.popupOptions.target = () => import(`${'./safetyHealthyMstPopup.vue'}`);
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeTargetPopup;
    },
    closeTargetPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.target.plans, { saiSafetyHealthyId: item.saiSafetyHealthyId }) === -1) {
            this.target.plans.push({
              saiSafetyHealthyTargetPlanId: uid(),  // 안전보건목표 계획 일련번호
              saiSafetyHealthyTargetId: this.popupParam.saiSafetyHealthyTargetId,  // 안전보건목표 일련번호
              saiSafetyHealthyId: item.saiSafetyHealthyId,  // 안전보건목표 마스터 일련번호
              targetName: item.targetName,  // 안전보건목표
              detailName: '',  // 세부추진사항
              deptName: item.deptName,  // 해당부서
              kpi: '',  // kpi 성과지표
              performanceGoal: '',  // 성과목표
              status: null,  // 현황
              achievementRate: 0,  // 달성율
              budget: 0,  // 예산
              performanceDetails: '',  // 실적내용
              remark: '',  // 비고
              month1: 'N',  // 1월 계획
              month2: 'N',  // 2월 계획
              month3: 'N',  // 3월 계획
              month4: 'N',  // 4월 계획
              month5: 'N',  // 5월 계획
              month6: 'N',  // 6월 계획
              month7: 'N',  // 7월 계획
              month8: 'N',  // 8월 계획
              month9: 'N',  // 9월 계획
              month10: 'N',  // 10월 계획
              month11: 'N',  // 11월 계획
              month12: 'N',  // 12월 계획
              regUserId: this.$store.getters.user.userId,  // 등록자
              editFlag: 'C'
            })
          }
        })
      }
    },
    innerBtnClicked(col, props) {
      this.target.plans.splice(props.rowIndex + 1, 0, {
        saiSafetyHealthyTargetPlanId: uid(),  // 안전보건목표 계획 일련번호
        saiSafetyHealthyTargetId: this.popupParam.saiSafetyHealthyTargetId,  // 안전보건목표 일련번호
        saiSafetyHealthyId: props.row.saiSafetyHealthyId,  // 안전보건목표 마스터 일련번호
        targetName: props.row.targetName,  // 안전보건목표
        detailName: props.row.detailName,  // 세부추진사항
        deptName: props.row.deptName,  // 해당부서
        kpi: '',  // kpi 성과지표
        performanceGoal: '',  // 성과목표
        status: null,  // 현황
        achievementRate: 0,  // 달성율
        budget: 0,  // 예산
        performanceDetails: '',  // 실적내용
        remark: '',  // 비고
        month1: 'N',  // 1월 계획
        month2: 'N',  // 2월 계획
        month3: 'N',  // 3월 계획
        month4: 'N',  // 4월 계획
        month5: 'N',  // 5월 계획
        month6: 'N',  // 6월 계획
        month7: 'N',  // 7월 계획
        month8: 'N',  // 8월 계획
        month9: 'N',  // 9월 계획
        month10: 'N',  // 10월 계획
        month11: 'N',  // 11월 계획
        month12: 'N',  // 12월 계획
        regUserId: this.$store.getters.user.userId,  // 등록자
        editFlag: 'C'
      })
    },
    deleteTargetPlan() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',  // 확인
          message: 'MSGEXCEPT', // 제외하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            if (!this.target.deletePlans) this.target.deletePlans = [];
            this.$_.forEach(selectData, item => {
              if (item.editFlag !== 'C'
                && this.$_.findIndex(this.target.deletePlans, { saiSafetyHealthyTargetPlanId: item.saiSafetyHealthyTargetPlanId })) {
                  this.target.deletePlans.push(item)
              }
              this.target.plans = this.$_.reject(this.target.plans, item);
            })
            this.$refs['table'].$refs['compo-table'].clearSelection();
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    openResult(row) {
      if (!row) return;
      this.$set(this.$data, 'planRow', row)
      // '[' + row.detailName + '] 상세'
      // this.$comm.getLangLabel('LBL0010070', {s1: row.detailName})
      this.popupOptions.title = this.$comm.getLangLabel('LBL0010070', {s1: row.detailName});  
      this.popupOptions.param = {
        saiSafetyHealthyTargetId: row.saiSafetyHealthyTargetId,
        saiSafetyHealthyTargetPlanId: row.saiSafetyHealthyTargetPlanId,
      };
      this.popupOptions.target = () => import(`${'./safetyHealthyTargetDetailPopup.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeResultPopup;
    },
    closeResultPopup(result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if (result && result.col1) {
        this.$set(this.planRow, 'results', result.col1)
      }
    },
    saveTarget() {
      if (this.isOld) {
        this.mappingType = 'PUT'
        this.saveUrl = transactionConfig.sai.safetyHealthy.update.url;
      } else {
        this.mappingType = 'POST'
        this.saveUrl = transactionConfig.sai.safetyHealthy.insert.url;
      }
      
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          if (this.$comm.validTable(this.grid.columns, this.target.plans)) {
            window.getApp.$emit('CONFIRM', {
              title: 'LBLCONFIRM',  // 확인
              message: 'MSGSAVE', // 저장하시겠습니까?   // 저장하시겠습니까?
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.target.regUserId = this.$store.getters.user.userId
                this.target.chgUserId = this.$store.getters.user.userId
        
                this.isSave = !this.isSave
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveTargetCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.saiSafetyHealthyTargetId = result.data
      this.getDetail();
    },
    deleteTarget() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',  // 확인
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.saiSafetyHealthyTargetId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    copyTarget() {
      this.popupOptions.title = "타 ESF 목표 및 추진계획/실적 검색"; 
      this.popupOptions.param = {
        type: "single",
      };
      this.popupOptions.target = () => import(`${"./safetyHealthyTargetPopup.vue"}`);
      this.popupOptions.width = "70%";
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeCopyTargetPopup;
    },
    closeCopyTargetPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$http.url = this.$format(this.detailUrl, data[0].saiSafetyHealthyTargetId);
        this.$http.type = "GET";
        this.$http.request((_result) => {
          this.target.plantCd = _result.data.plantCd
          this.target.targetYear = this.$comm.getThisYear();
          this.target.writeDeptCd = _result.data.writeDeptCd
          this.target.writeDt = this.$comm.getToday();
          this.target.regUserId = this.$store.getters.user.userId

          this.$_.forEach(_result.data.plans, item => {
            let _idx = this.$_.findIndex(this.target.plans, { saiSafetyHealthyId: item.saiSafetyHealthyId })
            _idx = _idx > -1 ? _idx : 0;
            this.target.plans.splice(_idx, 0, {
              saiSafetyHealthyTargetPlanId: uid(),  // 안전보건목표 계획 일련번호
              saiSafetyHealthyTargetId: this.popupParam.saiSafetyHealthyTargetId,  // 안전보건목표 일련번호
              saiSafetyHealthyId: item.saiSafetyHealthyId,  // 안전보건목표 마스터 일련번호
              targetName: item.targetName,  // 안전보건목표
              detailName: item.detailName,  // 세부추진사항
              deptName: item.deptName,  // 해당부서
              kpi: item.kpi,  // kpi 성과지표
              performanceGoal: item.performanceGoal,  // 성과목표
              status: null,  // 현황
              achievementRate: 0,  // 달성율
              budget: 0,  // 예산
              performanceDetails: '',  // 실적내용
              remark: '',  // 비고
              month1: 'N',  // 1월 계획
              month2: 'N',  // 2월 계획
              month3: 'N',  // 3월 계획
              month4: 'N',  // 4월 계획
              month5: 'N',  // 5월 계획
              month6: 'N',  // 6월 계획
              month7: 'N',  // 7월 계획
              month8: 'N',  // 8월 계획
              month9: 'N',  // 9월 계획
              month10: 'N',  // 10월 계획
              month11: 'N',  // 11월 계획
              month12: 'N',  // 12월 계획
              regUserId: this.$store.getters.user.userId,  // 등록자
              editFlag: 'C'
            })
          })
        })  
      }
    },
    datachange(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
  }
};
</script>